<template>
  <div>
    <!-- <b-row class="mb-2">
      <b-col cols="2" />
      <b-col v-if="opportunityType === 'New Business' || opportunityType === 'Existing Business'" cols="2" offset="8" />
    </b-row> -->
    <b-card-actions ref="campaigns" class="mb-2" no-body no-actions>
      <!-- !! Table Header -->
      <div class="mx-1">
        <b-row>
          <b-col
            lg="5"
            :sm="canCreateCampaign ? 4 : 8"
            :cols="canCreateCampaign ? 12 : 8"
          >
            <HeroInputText
              id="filter"
              v-model="tableConfig.filter"
              placeholder="Search"
              class="mb-0"
              @input="doTableFilter(500)"
            />
          </b-col>
          <b-col
            :class="[{'mt-1': canCreateCampaign}, 'mt-sm-0']"
            lg="3"
            :sm="canCreateCampaign ? 3 : 4"
            :cols="canCreateCampaign ? 6 : 4"
          >
            <HeroTablePerPage
              v-model="tableConfig.perPage"
              :options="tableConfig.perPageOptions"
              @input="doTableFilter(100)"
            />
          </b-col>
          <b-col
            v-if="canCreateCampaign"
            class="mt-1 mt-sm-0"
            order="4"
            lg="4"
            sm="5"
            cols="6"
          >
            <HeroButtonAction
              type="button"
              variant="primary"
              :disabled="opportunityRemaining < 1"
              btnclass="new-campaign w-auto ml-auto"
              @click="setOpportunityId"
            >
              Create New Campaign
            </HeroButtonAction>
          </b-col>
        </b-row>
      </div>
      <!-- table -->
      <b-table
        class="mt-2"
        :no-border-collapse="false"
        show-empty
        striped
        sticky-header="100%"
        :responsive="true"
        :items="items"
        :fields="fields"
        :per-page="0"
        :sort-by="tableConfig.sortBy"
        :sort-direction="tableConfig.sortDirection"
        :no-sort-reset="true"
        :no-local-sorting="true"
        @sort-changed="doTableSort"
      >
        <template #cell(name)="data">
          <div class="span span:hover" :style="`width: ${data.field.thwidth}px;`">
            <span v-if="data.item.demo_campaign" class="text-warning">●</span>
            <span v-if="!data.item.demo_campaign" class="text-success">●</span>
            <router-link :to="{name: 'campaign-detail', params: {id: data.item.campaign_id}}">
              {{ data.item.name }}
            </router-link>
          </div>
        </template>
        <template #cell(uniqued_selling_point)="data">
          <div class="span span:hover">
            {{ data.item.uniqued_selling_point }}
          </div>
        </template>
        <template #cell(actual_campaign_start_date)="data">
          {{ formatUsaDate(data.item.actual_campaign_start_date) }}
        </template>
        <template #cell(actual_campaign_end_date)="data">
          {{ formatUsaDate(data.item.actual_campaign_end_date) }}
        </template>
        <template #cell(brief_date)="data">
          {{ formatUsaDate(data.item.brief_date) }}
        </template>
        <template #cell(paused_date)="data">
          {{ formatUsaDate(data.item.paused_date) }}
        </template>
        <template #cell(unpaused_date)="data">
          {{ formatUsaDate(data.item.unpaused_date) }}
        </template>
        <!--        <template #cell(viewers)="data">-->
        <!--          <div class="span span:hover">-->
        <!--            {{ data.item.viewers.join(', ') }}-->
        <!--          </div>-->
        <!--        </template>-->
        <!--        <template #cell(line_notifications)="data">-->
        <!--          <div class="span span:hover">-->
        <!--            {{ data.item.line_notifications.map(x => x.email).join(', ') }}-->
        <!--          </div>-->
        <!--        </template>-->
        <template #cell(google_account_id)="data">
          <div class="span span:hover">
            {{ data.item.google_account_id.join(', ') }}
          </div>
        </template>
        <template #cell(facebook_account_id)="data">
          <div class="span span:hover">
            {{ data.item.facebook_account_id.join(', ') }}
          </div>
        </template>
        <template #cell(tiktok_account_id)="data">
          <div class="span span:hover">
            {{ data.item.tiktok_account_id.join(', ') }}
          </div>
        </template>
        <!--        <template #cell(did_phone)="data">-->
        <!--          <div class="span span:hover">-->
        <!--            {{ data.item.did_phone.join(', ') }}-->
        <!--          </div>-->
        <!--        </template>-->
      </b-table>
      <!-- !! Table Footer -->
      <div class="mx-1 mb-1">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <HeroTablePagination
              v-model="tableConfig.currentPage"
              :per-page="tableConfig.perPage"
              :total-rows="tableConfig.totalRows"
              @input="doTableFilter(100)"
            />
          </b-col>
          <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
            <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
          </b-col>
        </b-row>
      </div>
    </b-card-actions>
  </div>
</template>

<script>
import store from '@/store'
import {
  BRow,
  BCol,
  BTable,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import OpportunityCampaignFactory from '@/factories/Opportunity/OpportunityCampaign'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import Constant from '@/utils/Constant'
import moment from 'moment'
import DataFormatService from '@/services/DataFormatService'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCardActions,
    HeroButtonAction,
    HeroInputText,
    HeroTablePerPage,
    HeroTablePagination,
    HeroTableStatus,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    opportunityType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'opportunity_id',
        sortDirection: 'asc',
        timeInterval: moment(),
      },
      fields: [
        {
          label: 'Campaign name', key: 'name', stickyColumn: true, thwidth: '200',
        },
        { label: 'Duration', key: 'period' },
        { label: 'status', key: 'campaign_status' },
        { label: 'opportunity name', key: 'opportunity' },
        { label: 'Pricing model', key: 'pricing_model' },
        { label: 'brief date', key: 'brief_date' },
        { label: 'start date', key: 'actual_campaign_start_date' },
        { label: 'end date', key: 'actual_campaign_end_date' },
        { label: 'campaign pause date', key: 'paused_date' },
        { label: 'campaign restart date', key: 'unpaused_date' },
        { label: 'day left', key: 'day_left' },
        { label: 'kpi', key: 'kpi' },
        { label: 'per cycle budget', key: 'per_cycle_budget' },
        { label: 'heroAI campaign ID', key: 'alpha_campaign_id' },
        { label: 'google account ID', key: 'google_account_id' },
        { label: 'facebook account ID', key: 'facebook_account_id' },
        { label: 'tiktok account ID', key: 'tiktok_account_id' },
        // { label: 'bonus/discount', key: 'bonus_discount' },
        // { label: 'landing page type', key: 'landing_page_type' },
        // { label: 'landing page languages', key: 'landing_page_language' },
        // { label: 'uniqued selling point', key: 'uniqued_selling_point' },
        // { label: 'services focus', key: 'service_focus' },
        // { label: 'keywords ideas', key: 'keyword_ideas' },
        // { label: 'DID phone', key: 'did_phone' },
        // { label: 'creator', key: 'creator' },
        // { label: 'remark', key: 'remark' },
        { label: 'industry', key: 'industry' },
        // { label: 'media plan', key: 'media_plan' },
        { label: 'objective', key: 'objectives' },
        { label: 'country', key: 'country_code' },
        // { label: 'line notifications', key: 'line_notifications' },
        // { label: 'line notification language', key: 'line_notifications_language' },
        // { label: 'viewers', key: 'viewers' },
      ],
      items: [],
      opportunityCampaignList: OpportunityCampaignFactory.createFromJsonArray([{}]),
      opportunityRemaining: 0,
    }
  },
  computed: {
    hasMyCampaignAccess() {
      return this.$can('Access - My Campaign', 'Campaigns')
    },

    hasAllCampaignAccess() {
      return this.$can('Access - All Campaign', 'Campaigns')
    },

    canCreateCampaign() {
      return this.$can('Create Campaign', 'Opportunities > Campaign') && (this.opportunityType === 'New Business' || this.opportunityType === 'Existing Business')
    },
  },
  created() {
    this.formatUsaDate = DataFormatService.formatUsaDate
  },
  mounted() {
    this.doLoadData()
  },
  methods: {
    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    async doLoadData() {
      try {
        this.$refs.campaigns.showLoading = true

        const body = {
          objectId: this.$route.params.id,
          objectType: 'Opportunity',
        }

        // หาค่า Opportunity Remaining
        const balanceResponse = await axiosInstance.post('wallet-family/get-balance/', body)

        this.opportunityRemaining = balanceResponse.data.balance

        const {
          perPage, currentPage, filter, sortBy, sortDirection,
        } = this.tableConfig

        const qparam = {
          opportunity_id: this.$route.params.id, perPage, page: currentPage, q: filter, orderByColumn: sortBy, orderByDirection: sortDirection,
        }

        const userId = this.hasMyCampaignAccess && !this.hasAllCampaignAccess ? this.$store.getters['heroAiAuthentications/loginData']?.id : ''

        const campaignResponse = await axiosInstance.post(Constant.apiURL.campaign.list, { ...userId && { user_id: userId } }, { params: qparam })
        if (campaignResponse.data.status) {
          this.tableConfig.currentPage = Number(campaignResponse.data.data.current_page)
          this.tableConfig.perPage = Number(campaignResponse.data.data.per_page)
          this.tableConfig.totalRows = Number(campaignResponse.data.data.total)
          this.items = OpportunityCampaignFactory.createFromJsonArray(campaignResponse.data.data.data)
        } else {
          this.tableConfig.currentPage = 1
          this.tableConfig.totalRows = 0
          this.items = []
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        this.items = []
        this.tableConfig.totalRows = 0
      } finally {
        if (this.$refs.campaigns) {
          this.$refs.campaigns.showLoading = false
        }
      }
    },

    setOpportunityId() {
      store.commit('heroAiCampaign/setOpportunityId', this.$route.params.id)
      this.$router.push({ name: 'campaign-create' })
    },
  },
}
</script>

<style scoped>
.span {
  width: 100px; /* can be 100% ellipsis will happen when contents exceed it */
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.span:hover {
  white-space: normal;
}
.new-campaign{
  padding: 12px;
}
.b-table-sticky-header {
  max-height: calc(100vh - 350px);
}
</style>
