<template>
  <b-card no-body>
    <b-tabs card pills>
      <b-tabs v-model="tabIndex" align="left" style="margin-top: -50px">
        <b-tab
          v-if="opportunity"
          title="Details"
        >
          <div class="d-flex justify-content-end col-gap-4 m-1">
            <HeroButtonAction
              type="button"
              variant="primary"
              class="w-auto"
              @click="$bvModal.show('creative-briefing-list-modal')"
            >
              Creative Briefing List
            </HeroButtonAction>
            <CreativeBriefingListModal :opportunity-id="$route.params.id" />
            <HeroButtonAction
              type="button"
              variant="primary"
              :disabled="disableWalletButton"
              class="w-auto mt-0"
              @click="addToWalletFamily"
            >
              Add To Wallet Family
            </HeroButtonAction>
            <HeroButtonAction
              type="button"
              variant="primary"
              class="w-auto mt-0"
              @click="collapseBtn"
            >
              {{ buttonText }}
            </HeroButtonAction>
          </div>

          <app-collapse accordion :type="collapseType">
            <Incharge :incharge="opportunity.inCharge" :is-visible="isVisible || opportunityState.Incharge" @updatevisible="setAccordionState('Incharge')" />
            <Stage :stage="opportunity.stage" :is-visible="isVisible || opportunityState.Stage" @updatevisible="setAccordionState('Stage')" />
            <Information v-if="!isLoading" :information="opportunity.information" :is-visible="isVisible || opportunityState.Information" @updatevisible="setAccordionState('Information')" />
            <DealTerms :deal-terms="opportunity.deal_terms" :is-visible="isVisible || opportunityState.DealTerms" @updatevisible="setAccordionState('DealTerms')" />
            <Validation v-if="opportunity.validations" :validations="opportunity.validations" :is-visible="isVisible || opportunityState.Validation" @updatevisible="setAccordionState('Validation')" />
            <ClientBilling :client_billing="opportunity.client_billing" :is-visible="isVisible || opportunityState.ClientBilling" @updatevisible="setAccordionState('ClientBilling')" />
            <CampaignDetail :campaign="opportunity.campaign" :is-visible="isVisible || opportunityState.CampaignDetail" @updatevisible="setAccordionState('CampaignDetail')" />
            <AccountManagementInfo :account_management_info="opportunity.account_management_info" :is-visible="isVisible || opportunityState.AccountManagementInfo" @updatevisible="setAccordionState('AccountManagementInfo')" />
            <SystemInformation :system_information="opportunity.system_information" :is-visible="isVisible || opportunityState.SystemInformation" @updatevisible="setAccordionState('SystemInformation')" />
            <SalesOrder :is-visible="isVisible || opportunityState.SalesOrder" @updatevisible="setAccordionState('SalesOrder')" />
            <SalesHistory :is-visible="isVisible || opportunityState.SalesHistory" @updatevisible="setAccordionState('SalesHistory')" />
            <Notes :is-visible="isVisible || opportunityState.Notes" @updatevisible="setAccordionState('Notes')" />
            <Files :is-visible="isVisible || opportunityState.Files" @updatevisible="setAccordionState('Files')" />
            <Quotes :is-visible="isVisible || opportunityState.Quotes" @updatevisible="setAccordionState('Quotes')" />
            <InvoicePayments :is-visible="isVisible || opportunityState.InvoicePayments" @updatevisible="setAccordionState('InvoicePayments')" />
            <Products :is-visible="isVisible || opportunityState.Products" @updatevisible="setAccordionState('Products')" />
          </app-collapse>
        </b-tab>
        <b-tab
          v-if="$can('Access', 'Opportunities > Opportunity Product')"
          title="Opportunity Product"
        >
          <OpportunityProduct />
        </b-tab>
        <b-tab
          v-if="hasCampaignAccess"
          title="Campaign"
        >
          <Campaign v-if="!isLoading" :opportunity-type="opportunity.information.type" />
        </b-tab>
        <!--        <b-tab-->
        <!--          v-if="$can('Access', 'Opportunities > Payment Calendar')"-->
        <!--          title="Payment Calendar"-->
        <!--        >-->
        <!--          <b-alert-->
        <!--            class="mx-1"-->
        <!--            variant="info"-->
        <!--            :show="isShowWalletFamilyAlert && !isWalletFamilyLoading"-->
        <!--          >-->
        <!--            <div class="alert-body">-->
        <!--              This opportunity is not part of wallet family yet. It cannot generate payment calendar until have some transaction.-->
        <!--            </div>-->
        <!--          </b-alert>-->
        <!--          <PaymentCalendarDetails />-->
        <!--        </b-tab>-->
        <b-tab
          v-if="$can('Access', 'Wallets')"
          title="Wallets"
          @click="$router.push(
            {
              name: 'wallets-list',
              query: {
                walletType: 'Opportunity',
                objectId: $route.params.id,
                accountId: opportunity.information.account_id,
              },
            },
          )"
        >
          <div />
        </b-tab>
      </b-tabs>
    </b-tabs>
  </b-card>
</template>
<script>
import store from '@/store'
import ErrorService from '@/services/ErrorService'
import SweetAlert from '@/services/SweetAlert'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import Incharge from '@/views/heroai/opportunities/view/components/Incharge.vue'
import Stage from '@/views/heroai/opportunities/view/components/Stage.vue'
import Information from '@/views/heroai/opportunities/view/components/Information.vue'
import DealTerms from '@/views/heroai/opportunities/view/components/DealTerms.vue'
import Validation from '@/views/heroai/opportunities/view/components/Validation.vue'
import ClientBilling from '@/views/heroai/opportunities/view/components/ClientBilling.vue'
import CampaignDetail from '@/views/heroai/opportunities/view/components/Campaign.vue'
import AccountManagementInfo from '@/views/heroai/opportunities/view/components/AccountManagementInfo.vue'
import SystemInformation from '@/views/heroai/opportunities/view/components/SystemInformation.vue'
import SalesOrder from '@/views/heroai/opportunities/view/components/SalesOrder.vue'
import SalesHistory from '@/views/heroai/opportunities/view/components/SalesHistory.vue'
import Products from '@/views/heroai/opportunities/view/components/Products.vue'
import OpportunityProduct from '@/views/heroai/opportunities/view/product/view.vue'
// import PaymentCalendarDetails from '@/views/heroai/opportunities/view/PaymentCalendar.vue'
import Campaign from '@/views/heroai/opportunities/view/Campaign.vue'
import Constant from '@/utils/Constant'
import Opportunity from '@/models/Opportunity/index'
import OpportunityStateModal from '@/models/Opportunity/OpportunityState'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import CreativeBriefingListModal from '@/views/heroai/creative-briefing/components/CreativeBriefingListModal.vue'
import axiosInstance from '@/libs/axiosInstance'
import Notes from './view/components/Notes.vue'
import Files from './view/components/Files.vue'
import InvoicePayments from './view/components/InvoicePayments.vue'
import Quotes from './view/components/Quotes.vue'

export default {
  components: {
    CreativeBriefingListModal,
    HeroButtonAction,
    AppCollapse,
    Incharge,
    Stage,
    Information,
    DealTerms,
    Validation,
    SalesHistory,
    ClientBilling,
    CampaignDetail,
    AccountManagementInfo,
    SystemInformation,
    SalesOrder,
    Products,
    OpportunityProduct,
    // PaymentCalendarDetails,
    Campaign,
    Notes,
    Files,
    InvoicePayments,
    Quotes,
  },
  data() {
    return {
      opportunityState: new OpportunityStateModal({}),
      collapseType: 'border',
      opportunity: {},
      isVisible: false,
      isLoading: true,
      tabIndex: 0,
      disableWalletButton: true,
      isWalletFamilyLoading: true,
    }
  },
  computed: {
    // opportunitystate() {
    //   return store.getters['heroAiCampaign/getOpportunityState']
    // },

    buttonText() {
      return this.checkExpandState()
    },

    // isShowWalletFamilyAlert() {
    //   return !this.disableWalletButton
    // },

    hasCampaignAccess() {
      return this.$can('Access - My Campaign', 'Campaigns') || this.$can('Access - All Campaign', 'Campaigns')
    },
  },
  watch: {
    isLoading: {
      handler() {
        if (this.isLoading) {
          this.$swal({ ...SweetAlert.loading, text: 'Please wait while we are loading opportunity detail.' })
        } else {
          this.$swal().close()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getWalletFamily()
    this.getOpportunityDetail()
    this.opportunityState = store.getters['heroAiCampaign/getOpportunityState']
    if (!this.opportunityState) {
      this.opportunityState = new OpportunityStateModal({})
      store.commit('heroAiCampaign/setOpportunityState', new OpportunityStateModal({}))
    }
    this.checkAccordionState()
  },
  methods: {
    async getOpportunityDetail() {
      try {
        this.isLoading = true

        const qparam = {
          opportunity_id: this.$route.params.id,
        }
        const response = await axiosInstance.get(Constant.apiURL.salesforceOpportunityDetail, { params: qparam })
        this.opportunity = new Opportunity(response.data.data)
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.isLoading = false
      }
    },
    collapseBtn() {
      let ExpandAll = false
      if (this.buttonText === 'Expand All') {
        ExpandAll = true
      }
      const OpportunityState = this.opportunityState
      Object.keys(this.opportunityState).forEach(x => {
        OpportunityState[x] = ExpandAll
      })
      this.opportunityState = OpportunityState
      store.commit('heroAiCampaign/setOpportunityState', this.opportunityState)
      this.isVisible = ExpandAll
    },
    checkExpandState() {
      let AllCollapsed = true
      Object.keys(this.opportunityState).forEach(x => {
        if (this.opportunityState[x]) {
          AllCollapsed = false
        }
      })
      return AllCollapsed ? 'Expand All' : 'Collapse All'
    },
    checkAccordionState() {
      let AllCollapsed = true
      Object.keys(this.opportunityState).forEach(x => {
        if (this.opportunityState[x]) {
          AllCollapsed = false
        }
      })
      if (AllCollapsed && this.isVisible) {
        this.isVisible = false
      }
    },
    setAccordionState(propertyName) {
      const PreviousState = this.opportunityState[propertyName]
      this.opportunityState[propertyName] = !PreviousState
      store.commit('heroAiCampaign/setOpportunityState', this.opportunityState)
      this.checkAccordionState()
    },
    async addToWalletFamily() {
      try {
        const body = {}
        const url = `wallet-family/add-to-family/${this.$route.params.id}`
        const response = await axiosInstance.post(url, body)

        this.disableWalletButton = true

        await this.$swal({ ...SweetAlert.success, text: response.data.message })
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },
    async getWalletFamily() {
      try {
        const url = `wallet-family/get-wallet-family/${this.$route.params.id}`
        const response = await axiosInstance.get(url)

        this.disableWalletButton = response.data.disableButton
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.isWalletFamilyLoading = false
      }
    },
  },
}
</script>
