<template>
  <div class="mx-1">
    <b-row>
      <b-card-actions ref="opp_product_new" class="mb-2 w-100" no-body no-actions>
        <!-- !! Table Header -->
        <div class="mx-1">
          <b-row>
            <b-col cols="8" lg="5">
              <HeroInputText
                id="filter"
                v-model="tableConfig.filter"
                placeholder="Search"
                class="mb-0"
                @input="doTableFilter(500)"
              />
            </b-col>
            <b-col cols="4" lg="4">
              <HeroTablePerPage
                v-model="tableConfig.perPage"
                :options="tableConfig.perPageOptions"
                @input="doTableFilter(100)"
              />
            </b-col>
          </b-row>
        </div>
        <!-- table -->
        <b-row>
          <b-col cols="12">
            <b-table
              class="mt-2"
              :no-border-collapse="false"
              show-empty
              striped
              sticky-header="100%"
              :responsive="true"
              :items="items"
              :fields="tableConfig.fields"
              :per-page="0"
              :sort-by="tableConfig.sortBy"
              :sort-direction="tableConfig.sortDirection"
              :no-sort-reset="true"
              :no-local-sorting="true"
              @sort-changed="doTableSort"
            >
              <template #cell(description)="data">
                <div class="span">
                  {{ data.item.description }}
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <!-- !! Table Footer -->
        <div class="mx-1 mb-1">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <HeroTablePagination
                v-model="tableConfig.currentPage"
                :per-page="tableConfig.perPage"
                :total-rows="tableConfig.totalRows"
                @input="doTableFilter(100)"
              />
            </b-col>
            <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
              <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
            </b-col>
          </b-row>
        </div>
      </b-card-actions>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import Constant from '@/utils/Constant'
import ProductFactory from '@/factories/Opportunity/Product'
import moment from 'moment'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    BCardActions,
    HeroInputText,
    HeroTablePerPage,
    HeroTablePagination,
    HeroTableStatus,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'opportunity_id',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          { label: 'product', key: 'name', sortable: true },
          { label: 'quantity', key: 'quantity', sortable: true },
          { label: 'sales price', key: 'sales_price', sortable: true },
          { label: 'management fee', key: 'management_fee', sortable: true },
          { label: 'gross profit', key: 'gross_profit', sortable: true },
          { label: 'line description', key: 'description', sortable: true },
          { label: 'list price', key: 'price', sortable: true },
          { label: 'vendor name', key: 'vendor_name', sortable: true },
          { label: 'vendor cost', key: 'vendor_cost', sortable: true },
          { label: 'line item number', key: 'line_item_number', sortable: true },
        ],
      },
    }
  },
  mounted() {
    this.doLoadData()
  },
  methods: {
    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    async doLoadData() {
      try {
        this.$refs.opp_product_new.showLoading = true

        const {
          perPage, currentPage, filter, sortBy, sortDirection,
        } = this.tableConfig

        const qparam = {
          opportunity_id: this.$route.params.id,
          perPage,
          page: currentPage,
          q: filter,
          orderByColumn: sortBy,
          orderByDirection: sortDirection,
        }

        const response = await axiosInstance.post(Constant.apiURL.salesforceOpportunityGetProducts, qparam)
        if (response.data.status) {
          this.tableConfig.currentPage = Number(response.data.data.current_page)
          this.tableConfig.perPage = Number(response.data.data.per_page)
          this.tableConfig.totalRows = Number(response.data.data.total)
          this.items = ProductFactory.createFromJsonArray(response.data.data.data)
        } else {
          this.tableConfig.currentPage = 1
          this.tableConfig.totalRows = 0
          this.items = []
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        this.items = []
        this.tableConfig.totalRows = 0
      } finally {
        if (this.$refs.opp_product_new) {
          this.$refs.opp_product_new.showLoading = false
        }
      }
    },

    columnDate(data) {
      if (data) {
        return moment(data).tz('Asia/Bangkok').format('MMMM DD, YYYY')
      }

      return null
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.span {
  width: 100px; /* can be 100% ellipsis will happen when contents exceed it */
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.span:hover {
  white-space: normal;
}
</style>
